import { type Category, type MenuItem as MenuItemType, type Modifier, actions } from '@packages/core';
import { currentOrderItem } from '../orderStore';

interface Props {
  menuItem: MenuItemType;
  category: Category;
  sizeModifiers: Modifier[];
  disabled: boolean;
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export const MenuItem = ({ menuItem, sizeModifiers, category, disabled }: Props) => {
  if (!menuItem) return null;

  const handleClick = async () => {
    if (disabled) return; // do nothing

    // Avoid using `api.` call here because we already have the data.
    const orderItem = actions.orderItem.init({ menuItem, category });
    currentOrderItem.set(orderItem);
  };

  return (
    <div
      className={classNames(
        disabled ? 'text-black' : 'cursor-pointer text-black sm:hover:border-black',
        'flex select-none border-t border-soft-dark bg-white p-2 sm:rounded-lg sm:border-2 sm:p-4',
      )}
      onClick={handleClick}
    >
      {/* TODO: Add support for menu item images */}
      {/*<div className="mr-4 flex-shrink-0 self-center">*/}
      {/*  <svg*/}
      {/*    className="h-16 w-16 border border-gray-300 bg-white text-gray-300"*/}
      {/*    preserveAspectRatio="none"*/}
      {/*    stroke="currentColor"*/}
      {/*    fill="none"*/}
      {/*    viewBox="0 0 200 200"*/}
      {/*    aria-hidden="true"*/}
      {/*  >*/}
      {/*    <path vectorEffect="non-scaling-stroke" strokeWidth={1} d="M0 0l200 200M0 200L200 0" />*/}
      {/*  </svg>*/}
      {/*</div>*/}
      <div>
        <h4 className="text-md font-semibold">{menuItem.name}</h4>
        <p className="mt-1 text-sm">{actions.menuItem.priceForDisplay(menuItem, sizeModifiers)}</p>
      </div>
    </div>
  );
};
